import { getEmailNewEditorUrl } from 'modules/email/utils/get-email-new-editor-url'
import { MailingId } from 'modules/mailing/types/mailing-interface'
import React from 'react'
import Frame from 'react-frame-component'
import { twJoin } from 'tailwind-merge'
import { ExtendedState } from '../../types/common'

export type NewEditorProps = {
  tempState: ExtendedState
  isPreFetching?: boolean
  mailingId: MailingId
}

export const NewEditor = ({ tempState, isPreFetching, mailingId }: NewEditorProps) => {
  return (
    <Frame className={twJoin('h-full lg:h-[100vh] w-full', isPreFetching && 'pointer-events-none')}>
      <a
        style={{ position: 'fixed', top: 0, bottom: 0, left: 0, right: 0 }}
        target={'_parent'}
        href={getEmailNewEditorUrl(mailingId as MailingId)}
      />
      <div dangerouslySetInnerHTML={{ __html: tempState.html ?? '' }} />
    </Frame>
  )
}
