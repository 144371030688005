import React from 'react'
import { useEmailOptions } from 'shared/components/dashboard-mail-editor/hooks/use-email-options'
import FormInput from 'shared/components/form-input'
import { SectionMessage } from 'shared/components/section-message'
import SenderEmailSelect from 'shared/components/sender-email-select'
import { useLocoTranslation } from 'shared/hooks/use-loco-translation'
import { isBlacklistEmailDomain } from 'shared/utils/is-blacklist-email-domain'
import { DashboardMailEditorCommonProps, ExtendedState } from '../types/common'

export interface EmailSettingsBlockProps<T extends ExtendedState>
  extends Pick<
    DashboardMailEditorCommonProps<T>,
    'isPreFetching' | 'inModal' | 'errors' | 'tempState' | 'onChangeEmail' | 'disabled'
  > {}

const EmailSettingsBlock = <T extends ExtendedState>({
  disabled,
  errors,
  inModal,
  isPreFetching,
  onChangeEmail,
  tempState,
}: EmailSettingsBlockProps<T>) => {
  const { t } = useLocoTranslation()
  const { data: options } = useEmailOptions()

  return (
    <div className={'overflow-y-hidden rounded-md bg-white w-full flex flex-col'}>
      <span
        className={`${
          inModal ? 'pb-5' : 'p-5'
        } border-b border-gray/30 font-bold text-darkblue text-[17px]`}
      >
        {t('dashboard.email.email_settings')}
      </span>
      <div className={`${inModal ? 'pt-5' : 'p-5'} gap-5 flex flex-col`}>
        <FormInput
          disabled={disabled}
          isPreFetching={isPreFetching || !options}
          value={tempState?.fromName || ''}
          onChange={e => tempState && onChangeEmail({ ...tempState, fromName: e.target.value })}
          error={errors.fromName}
          required
          label={t('dashboard.email.sender_name')}
        />
        {!!tempState?.fromEmail && isBlacklistEmailDomain(tempState?.fromEmail) && (
          <SectionMessage>{t('dashboard.email.new_google_policy_warning_message')}</SectionMessage>
        )}
        <SenderEmailSelect
          value={tempState?.fromEmail || ''}
          onChange={value => tempState && onChangeEmail({ ...tempState, fromEmail: value })}
          error={errors.fromEmail}
          required
          disabled={disabled}
          isPreFetching={isPreFetching}
        />
      </div>
    </div>
  )
}

export default EmailSettingsBlock
